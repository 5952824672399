import React, { useEffect, useState } from "react";
import {
  MODAL_CROSS,
  USER_PROFILE,
  VECTORLEFT,
  VECTORRIGHT,
  COPYICON,
  EDITINGICON,
  CHECKOUT,
  SEARCH_ICON,
} from "../../../Middleware/images";
import "../../../assets/style/customer-leads.css";
import "./attendence.css";
import { useNavigate } from "react-router-dom";
import Modals from "../../../components/modal";
import Services from "../../../Helper/services";
import DEVELOPMENT_CONFIG from "../../../Helper/config";
import ReactLoader from "../../../Loader/react-loader";
import ROUTES from "../../../Middleware/routes";
import TITLES from "../../../Helper/title";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ERR_MESSAGE from "../../../Helper/error-helper";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  border: "1px solid #6ED7EF",
  p: 0,
  outline: 0,
  textAlign: "center",
  overflow: "hidden",
  overflowY: "scroll",
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  border: "1px solid #6ED7EF",
  p: 0,
  outline: 0,
  textAlign: "center",
};

function Attendence() {
  const [staffId, setStaffId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [day, setDay] = useState("");
  const [filterItems, setFilterItems] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [attendanceDay, setAttendanceDay] = useState({})
  const [search, setSearch] = useState('')

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [mark, setMark] = useState("");

  async function handleSearch(value) {
    let result = await Services.getService(`admin/get-staff-attendance?month=${month}&year=${year}&search=${value}`);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setStaffList(result?.body);
    } else {
    }
  }

  const handleDeleteOpen = (item, day) => {
    setOpenDelete(true);
    setStaffId(item.id)
    setDay(day)
  };

  const handleDeleteClose = () => setOpenDelete(false);
  const handleOpen = (item, day) => {
    setOpen(true);
    setStaffId(item.id)
    setDay(day)
  };

  const handleClose = () => setOpen(false);

  const handleOpenEdit = (attendanceObj, item, day) => {
    console.log(attendanceObj, "jjj")
    setOpenEdit(true);
    setAttendanceDay(attendanceObj)
    setStaffId(item.id)
    setMark(attendanceObj.attendance_status)
    setDay(day)
    // localStorage.setItem("attendanceObj", JSON.stringify(attendanceObj))
  };

  const handleCloseEdit = () => setOpenEdit(false);
  async function getApi() {
    setLoading(true);
    let result = await Services.getService(
      `admin/get-staff-attendance?month=${month}&year=${year}`
    );
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setStaffList(result?.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    getApi();
  }, [year, month]);

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!checkIn) {
      formIsValid = false;
      errors["checkIn"] = ERR_MESSAGE.CHECK_IN;
    }
    setErrors(errors);
    return formIsValid;
  };

  function convertDay(dayWithSuffix) {
    return parseInt(dayWithSuffix).toString().padStart(2, "0");
  }

  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    minutes = minutes.toString().padStart(2, "0");

    return `${hours}:${minutes} ${period}`;
  }

  const handleCheckIn = async (e) => {
    e.preventDefault();
    const attendance_dated = `${year}-${month}-${convertDay(day)}`
    if (handleValidation()) {
      setLoading(true);
      let data = {
        "staff_id": staffId,
        "attendance_date": attendance_dated,
        "check_in_out": checkIn
      }
      let result = await Services.postService(`admin/add-staff-attendance`, data);
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        toast.success(result.message);
        getApi();
        setOpen(false)
        setCheckIn('')
      } else {
        setLoading(false);
      }
    }
  };

  const handleValidation2 = () => {
    let errors = {};
    let formIsValid = true;

    if (!checkOut) {
      formIsValid = false;
      errors["checkOut"] = ERR_MESSAGE.CHECK_IN;
    }
    if (!mark) {
      formIsValid = false;
      errors["mark"] = ERR_MESSAGE.EMPTY_OPTION;
    }

    setErrors(errors);
    return formIsValid;
  };
  const handleValidation3 = () => {
    let errors = {};
    let formIsValid = true;
    if (!mark) {
      formIsValid = false;
      errors["mark"] = ERR_MESSAGE.EMPTY_OPTION;
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleCheckOut = async (e) => {
    e.preventDefault();
    const attendance_dated = `${year}-${month}-${convertDay(day)}`
    if (handleValidation2()) {
      setLoading(true);
      let data = {
        "staff_id": staffId,
        "attendance_date": attendance_dated,
        "check_in_out": checkOut,
        "attendance_status": mark,
      }
      let result = await Services.postService(`admin/add-staff-attendance`, data);
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        toast.success(result.message);
        getApi();
        setOpenDelete(false)
        setCheckOut("")
        setMark('')
      } else {
        setLoading(false);
      }
    }
  };

  const handleLeave = async (item, day) => {
    const attendance_dated = `${year}-${month}-${convertDay(day)}`;
    setLoading(true);
    let data = {
      "staff_id": item.id,
      "attendance_date": attendance_dated,
      "attendance_status": 3,
    };
    let result = await Services.postService(`admin/add-staff-attendance`, data);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      toast.success(result.message);
      getApi();
      setOpenDelete(false);
    } else {
      setLoading(false);
    }

  };


  useEffect(() => {
    updateDaysInMonth();
  }, [month, year]);

  const getOrdinalSuffix = (day) => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };

  const updateDaysInMonth = () => {
    const days = new Date(year, month, 0).getDate();
    setDaysInMonth(Array.from({ length: days }, (_, i) => getOrdinalSuffix(i + 1)));
  };

  const handlePrevMonth = () => {
    setMonth((prev) => (prev > 1 ? prev - 1 : 12));
    if (month === 1) setYear(year - 1);
  };

  const handleNextMonth = () => {
    setMonth((prev) => (prev < 12 ? prev + 1 : 1));
    if (month === 12) setYear(year + 1);
  };

  const handleCheckInChange = (id, value) => {
    setAttendanceDay((prevState) => ({
      ...prevState,
      check_in_out_details: prevState.check_in_out_details.map((detail) =>
        detail.id === id ? { ...detail, check_in: value } : detail
      ),
    }));
  };

  const handleCheckOutChange = (id, value) => {
    setAttendanceDay((prevState) => ({
      ...prevState,
      check_in_out_details: prevState.check_in_out_details.map((detail) =>
        detail.id === id ? { ...detail, check_out: value } : detail
      ),
    }));
  };


  const handleClearAll = async () => {
    setLoading(true);
    const attendance_dated = `${year}-${month}-${convertDay(day)}`;
    let data = {
      "staff_id": staffId,
      "attendance_date": attendance_dated,
      "clear_all": "3",
    };
    let result = await Services.postService(`admin/edit-staff-attendance`, data);
    if (result.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      toast.success(result.message);
      getApi();
      setOpenEdit(false);
    } else {
      setLoading(false);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault()
    if (handleValidation3()) {
      const attendance_dated = `${year}-${month}-${convertDay(day)}`;
      setLoading(true);
      let data = {
        "staff_id": staffId,
        "attendance_date": attendance_dated,
        "attendance_status": mark,
        "attendance_details": attendanceDay.check_in_out_details
      };
      let result = await Services.postService(`admin/edit-staff-attendance`, data);
      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        toast.success(result.message);
        getApi();
        setOpenEdit(false);
      } else {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>{TITLES.STAFF}</title>
      </Helmet>
      {loading && <ReactLoader />}
      <Modals />
      <section className="main-spacing mt-3 main-height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row pb-2">
                <div className="col-12 col-md-6 ">
                  <div className="leads-main d-block d-sm-flex align-items-center gap-2 gap-md-5 mb-2 mb-md-0 ">
                    <div className="leads-head my-3">
                      <h3>Attendence</h3>
                    </div>
                    <div className="search-category position-relative ">
                      <form className="d-flex" >
                        <input className="form-control ms-0 ms-sm-2 nav-input-search cus-nav-input-search" type="search" placeholder="Search staff..."
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value)
                            handleSearch(e.target.value)
                          }}
                        />
                        <img src={SEARCH_ICON} className="search-icon custom-search-icon" alt='' />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div className="d-flex gap-2 mx-4 my-2 justify-content-end">
                    <select
                      value={year}
                      onChange={(e) => setYear(parseInt(e.target.value))}
                      className="select-dropdown"
                    >
                      {[...Array(4)].map((_, index) => {
                        const year = new Date().getFullYear() - index;
                        return (
                          <option style={{ cursor: 'pointer' }} key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                    <div class="month-selector d-flex justify-content-center align-items-center px-3">
                      <button class="arrow-button" onClick={handlePrevMonth} id="prev-month">
                        <img src={VECTORLEFT} className="" alt="" />
                      </button>
                      <span className="month-label">
                        {moment(month, "MM").format("MMM")}
                      </span>
                      <button onClick={handleNextMonth} class="arrow-button" id="next-month">
                        <img src={VECTORRIGHT} className="" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive attendence-table-responsive" >
                  {
                      (staffList.length == 0 && search != ""
                      ) ? <div className='notFound'>No staff found</div>:
                      <div style={{ maxHeight: '100vh', overflowY: 'scroll', overflowX: 'scroll' }}>
                      <table className="table attendence-table">
                        <thead className="sticky-head">
                          <tr className="sticky-row">
                            <th scope="col" className="attendence-table-head heading-attendence">
                              Employee Name
                            </th>
                            {daysInMonth.map((day) => (
                              <th key={day} className="attendence-table-head text-center">
                                {day}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            staffList.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td
                                    scope="row"
                                    className="leads-data-head attendence-table-data lead-width vertical-heading"
                                  >
                                    <span className="d-flex customer-pic-head">
                                      <img
                                        src={USER_PROFILE}
                                        className="attendence-img me-3"
                                        alt=""
                                      />
                                      <div className="">
                                        <span class="attendance-name text-start">
                                          {item.name}
                                        </span>
                                        <span className="d-block attendance-text text-start my-2">
                                          {item?.role_id == 1 ? "Admin" : item?.role_id == 2 ? "Manager" : item?.role_id == 3 ? "Receptionist" : "Trainer"}
                                        </span>
                                        <div className="shift-timing">
                                          Shift Timming: {item.shift_hours}hours
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  {daysInMonth.map((day, index) => {
                                    const formattedDay = convertDay(day);
                                    const attendanceForDay = item.attendance_details.find((attendance) => {
                                      const attendanceDay = attendance.attendance_date.split('-')[2];
                                      return attendanceDay === formattedDay;
                                    });
                                    return (
                                      <td className="attendence-table-data position-relative attendance-width">
                                        {
                                          attendanceForDay?.check_in_out_details?.length > 0 ?
                                            <>
                                              <div className="timings-data pb-4">
                                                {
                                                  attendanceForDay?.check_in_out_details?.map((item) => (
                                                    <>
                                                      {
                                                        item?.check_in != null &&
                                                        <span className="timing-in text-start mb-2">
                                                          IN: {convertTo12HourFormat(item.check_in)}
                                                        </span>
                                                      }
                                                      {
                                                        item?.check_out != null &&
                                                        <span className="timing-out text-start mb-2">
                                                          OUT: {convertTo12HourFormat(item?.check_out)}
                                                        </span>
                                                      }
                                                    </>
                                                  ))
                                                }
                                              </div>
                                              <div className="timing-edit" onClick={() => handleOpenEdit(attendanceForDay, item, day)} style={{ cursor: 'pointer' }} >
                                                <img src={EDITINGICON} className="" alt="" />
                                              </div>
                                            </> :
                                            attendanceForDay?.attendance_status == "3" ? <div className="timings-data pb-4">
                                              <span className="timing-out text-start mb-2">
                                                On Leave
                                              </span>
                                            </div> : <div className="d-flex mark-text text-start justify-content-center align-items-center fw-400">
                                              <input
                                                type="checkbox"
                                                className="custom-checkbox"
                                                id={`html${item.id}-${index}`} onClick={() => handleLeave(item, day)}
                                              // checked={checke`html${index}`] } 
                                              />
                                              <label htmlFor={`html${item.id}-${index}`} className="custom-checkbox-label">
                                                Mark as leave
                                              </label>
                                            </div>
                                        }
                                        {
                                          attendanceForDay?.check_in_out_details[attendanceForDay?.check_in_out_details.length - 1]?.check_out == null && attendanceForDay?.check_in_out_details?.length > 0 ?
                                            <div className="timing-copy" style={{ cursor: 'pointer' }}>
                                              <img src={CHECKOUT} className="" alt="" onClick={() => handleDeleteOpen(item, day)} />
                                            </div> :
                                            <div className="timing-copy" style={{ cursor: 'pointer' }}>
                                              <img src={COPYICON} className="" alt="" onClick={() => handleOpen(item, day)} />
                                            </div>
  
                                        }
  
                                        {
                                          (attendanceForDay?.attendance_status == 1 || attendanceForDay?.attendance_status == 2) &&
                                          <div className="full-day-text">{attendanceForDay?.attendance_status == 2 ? "Half Day" : "Full Day"}</div>
                                        }
  
                                      </td>
                                    );
                                  })}
  
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  }
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal-head staff-modal-head attendance-modal-head">
            <div
              onClick={handleClose}
              className=" position-relative mb-0 text-end close-modal-btn"
              style={{ cursor: "pointer" }}
            >
              <img src={MODAL_CROSS} className="img-fluid" alt="" />
            </div>
            <div className="modal-staff-header position-relative text-center">
              <h2 className="customer-modal-text fw-600">Add Time</h2>
            </div>
            <form className="create-field" onSubmit={handleCheckIn}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0 d-flex">Check In</label>
                    <input
                      name="checkIn"
                      value={checkIn}
                      onChange={(e) => {
                        setErrors('')
                        setCheckIn(e.target.value)
                      }}
                      type="time"
                      className="form-control common-input time-input0"
                      placeholder="Select Time"
                    />
                    {errors.checkIn && (
                      <span className="err_msg d-flex">{errors.checkIn}</span>
                    )}
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end align-self-end py-3 mb-1 px-3 text-center position-relative ">
                  <button type="submit" className="btn delete-btn  w-100">
                    submit{" "}
                  </button>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
        {openEdit && <Modal
          open={openEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"

        >
          <Box sx={style} className="modal-head staff-modal-head attendance-modal-head">
            <div
              onClick={handleCloseEdit}
              className="position-relative mb-0 text-end close-modal-btn"
              style={{ cursor: "pointer" }}
            >
              <img src={MODAL_CROSS} className="img-fluid" alt="Close modal" />
            </div>
            <div className="modal-staff-header position-relative text-center">
              <h2 className="customer-modal-text fw-600">Edit Time</h2>
            </div>
            <form className="create-field" onSubmit={handleEdit}>
              <div className="row">
                <div className="col-md-12">
                  {attendanceDay?.check_in_out_details?.map((detail) => (
                    <div key={detail.id} className="mb-2 mb-xxl-3">
                      {
                        detail.check_in != null && <> <label className="form-label mb-0 d-flex">
                          Check In
                        </label>
                          <input
                            name={`checkIn-${detail.id}`}
                            value={detail.check_in}
                            onChange={(e) => {
                              setErrors('');
                              handleCheckInChange(detail.id, e.target.value);
                            }}
                            type="time"
                            className="form-control common-input time-input0"
                            placeholder="Select Time"
                          />
                          {/* {errors[`checkIn-${detail.id}`] && (
                            <span className="err_msg d-flex">
                              {errors[`checkIn-${detail.id}`]}
                            </span>
                          )} */}
                        </>
                      }
                      {
                        detail.check_out != null && <> <label className="form-label mb-0 d-flex mt-2">
                          Check Out
                        </label>
                          <input
                            name={`checkOut-${detail.id}`}
                            value={detail.check_out || ""}
                            onChange={(e) => {
                              setErrors('');
                              handleCheckOutChange(detail.id, e.target.value);
                            }}
                            type="time"
                            className="form-control common-input time-input0"
                            placeholder="Select Time"
                          />
                          {/* {errors[`checkOut-${detail.id}`] && (
                            <span className="err_msg d-flex">
                              {errors[`checkOut-${detail.id}`]}
                            </span>
                          )} */}
                        </>
                      }
                    </div>
                  ))}
                  {
                    (attendanceDay.attendance_status == 2 || attendanceDay.attendance_status == 1) &&
                    <div className="mb-2 mb-xxl-3">
                      <label className="form-label mb-0 d-flex">MARK AS</label>
                      <select
                        name="mark"
                        value={mark}
                        onChange={(e) => {
                          setErrors('');
                          setMark(e.target.value);
                        }}
                        className="form-select common-input"
                      >
                        <option value="">Select</option>
                        <option value="1">Full Day</option>
                        <option value="2">Half Day</option>
                      </select>
                      {errors.mark && (
                        <span className="err_msg d-flex">{errors.mark}</span>
                      )}
                    </div>
                  }

                </div>

                <div className="col-12 d-flex justify-content-end align-self-end py-3 mb-1 px-3 text-center position-relative">
                  <button type="submit" className="btn delete-btn w-100">Submit</button>
                </div>

                <div
                  className="col-12 d-flex justify-content-center align-self-end pb-2 mb-1 px-3 text-center position-relative"
                  style={{ color: "#ed6214", textDecoration: "underline", fontWeight: "600", cursor: "pointer" }}
                  onClick={handleClearAll}
                >
                  Clear All
                </div>
              </div>
            </form>
          </Box>
        </Modal>}


        <Modal
          open={openDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal-head staff-modal-head">
            <div
              onClick={handleDeleteClose}
              className=" position-relative mb-0 text-end close-modal-btn"
              style={{ cursor: "pointer" }}
            >
              <img src={MODAL_CROSS} className="img-fluid" alt="" />
            </div>
            <div className="modal-staff-header position-relative text-center">
              <h2 className="customer-modal-text fw-600">Add Time</h2>
            </div>
            <form className="create-field" onSubmit={handleCheckOut}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0 d-flex">Check Out</label>

                    <input
                      name="checkOut"
                      value={checkOut}
                      onChange={(e) => {
                        setErrors('')
                        setCheckOut(e.target.value)
                      }}
                      type="time"
                      className="form-control common-input time-input0"
                      placeholder="Select Time"
                    />
                    {errors.checkOut && (
                      <span className="err_msg  d-flex">{errors.checkOut}</span>
                    )}
                  </div>

                  <div className="mb-2 mb-xxl-3">
                    <label className="form-label mb-0 d-flex">MARK AS</label>
                    <select name='gender' value={mark} onChange={(e) => setMark(e.target.value)} className="form-select common-input">
                      <option value="" selected>Select</option>
                      <option value="1">Full Day</option>
                      <option value="2">Half Day</option>
                    </select>
                    {errors.mark && <span className="err_msg  d-flex">{errors.mark}</span>}
                  </div>
                </div>

                <div className="col-12 d-flex justify-content-end align-self-end py-3 mb-1 px-3 text-center position-relative ">
                  <button type="submit" className="btn delete-btn  w-100">
                    submit{" "}
                  </button>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </section>
      <ToastContainer />
    </>
  );
}
export default Attendence;
