import React, { useState, useEffect } from "react";
import { LOGIN_LOGO, NOTIFICATION_ICON, PROFILE_ICON, SEARCH_ICON } from "../Middleware/images";
import { NavLink, useNavigate } from "react-router-dom";
import "../assets/style/header.css"
import ROUTES from "../Middleware/routes";
import { useLayoutContext } from "../context/context";

function Header() {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchStart, setSearchStart] = useState(false)
    const navigate = useNavigate();
    const { setSearchQuery } = useLayoutContext();
    const handleClick = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("company_id");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("role_id");
        localStorage.removeItem("specialization");
        localStorage.removeItem("specialization1");
        localStorage.removeItem("exerciseDetailsDay1")
        localStorage.removeItem("exerciseDetailsDay2")
        localStorage.removeItem("exerciseDetailsDay3")
        localStorage.removeItem("exerciseDetailsDay4")
        localStorage.removeItem("exerciseDetailsDay5")
        localStorage.removeItem("exerciseDetailsDay6")
        navigate(ROUTES.LOGIN)
    }

    const handleClick1 = () => {
        localStorage.removeItem("exerciseDetailsDay1");
        localStorage.removeItem("exerciseDetailsDay2");
        localStorage.removeItem("exerciseDetailsDay3");
        localStorage.removeItem("exerciseDetailsDay4");
        localStorage.removeItem("exerciseDetailsDay5");
        localStorage.removeItem("exerciseDetailsDay6");
    }

    useEffect(() => {
        if (searchStart) {
            setSearchQuery(searchTerm);
            navigate(ROUTES.CUSTOMERS);
            setSearchStart(false)
        }

    }, [searchTerm]);


    return (
        <>
            {/* header-section */}
            <section className='main-header main-spacing'>
                <nav className="navbar navbar-expand-lg bg-white">
                    <div className="container-fluid">
                        <a className="navbar-brand header-brand me-0"><img src={LOGIN_LOGO} className="header-logo" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse nav-top-head" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item  position-relative">
                                    <form className="d-flex search-header" >
                                        <input className="form-control ms-2 nav-input-search" type="search" placeholder="Search customers..."
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value)
                                                setSearchStart(true)
                                            }} />
                                        <img src={SEARCH_ICON} style={{ cursor: 'pointer' }} className="search-icon"
                                        />
                                    </form>
                                </li>
                            </ul>
                            <ul className="navbar-nav mb-2 mb-lg-0 d-flex header-navheads">
                                <li className="nav-item mx-0 mx-lg-2">

                                    <select name='Select branch' className="form-select common-input header-branch" aria-label="Default select example" >
                                        <option value="2"> Branch</option>
                                        <option value="2">10 Phase</option>
                                        <option value="1">3b2</option>
                                    </select>
                                </li>


                                <li className="nav-item mx-3 mx-lg-2">
                                    <a className="nav-link header-icons" aria-current="page"><img src={NOTIFICATION_ICON} className="header-img" /></a>
                                </li>
                                <li className="nav-item mx-0 mx-lg-2">
                                    <div className="dropdown">
                                        <button type="button" className="btn leads-btn header-leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><img src={PROFILE_ICON} className="header-img" /></button>
                                        <ul className="dropdown-menu header-dropdown-menu">
                                            <li><a className="dropdown-item header-drop-items">Setting</a></li>
                                            <li><a className="dropdown-item header-drop-items" >Profile </a></li>
                                            <li><a className="dropdown-item header-drop-items" onClick={handleClick}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse straight-line" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mt-3 dash-header">
                                <li className="nav-item header-nav-item dask-link">
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.DASHBOARD}>Dashboard</NavLink>                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.CUSTOMERS}>Customers</NavLink>
                                    {/* <a className="nav-link header-nav-link" aria-current="page" href="">Customers</a> */}
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.LEADES}>Leads</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.STAFF}>Staff</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.ATTENDENCE}>Attendence</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <a className="nav-link header-nav-link" aria-current="page" >Sales</a>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.WORKOUT}>Workout</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink to={ROUTES.EXERCISE} className="nav-link header-nav-link" aria-current="page" >Exercises</NavLink>
                                </li>
                                <li className="nav-item header-nav-item">
                                    <a className="nav-link header-nav-link" aria-current="page" >Settings</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    )
}
export default Header;